import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=7426508f"
import script from "./_slug.vue?vue&type=script&lang=js"
export * from "./_slug.vue?vue&type=script&lang=js"
import style0 from "./_slug.vue?vue&type=style&index=0&id=7426508f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBreadcrumbs: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/AppBreadcrumbs.vue').default,AppLeftSidebar: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/AppLeftSidebar.vue').default,PageBlocks: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/PageBlocks.vue').default})
